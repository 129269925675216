<template>
  <div id="app">
    <sa-index></sa-index>
  </div>
</template>

<script>

import disableDevtool from 'disable-devtool';
import SaIndex from './sa-resources/index/sa-index.vue';
export default {
  name: 'App',
  components: {
    SaIndex
  }
}

if (process.env.VUE_APP_DISABLE_DEVTOOL === 'true') {
  var options = {
    md5: '9966701902d35c77db4ac559d1ec7ea1',
    url: 'https://www.baidu.com',
    disableMenu: false
  }
  disableDevtool(options);
}
</script>

<style>
</style>
